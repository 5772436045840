@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;
@tailwind colors;

@layer base {
    @font-face {
        font-family: "Montserrat", sans-serif;
    }
}

/*.tooltip {*/
/*    @apply invisible absolute;*/
/*}*/

/*.has-tooltip:hover .tooltip {*/
/*    @apply visible z-50*/
/*}*/

.menu :where(li.bordered>*) {
    border-color: #4C637D;
}

.menu :where(li.bordered>*):active {
    background-color: #4C637D;
}
